import React from "react";
import Footer from "../components/Footer"
import { Link } from "gatsby";
import { ChevronLeftIcon } from "@heroicons/react/outline"

export default function Contact() {
    return (
      <div>
      <div className="min-h-screen bg-gray-50 py-40 flex flex-col">
        <div className="hero-section">
          <div className="container mx-auto px-8 sm:px-48">
            <Link className="back-home flex items-center" to="/"><ChevronLeftIcon className="inline-block mr-1 h-4 w-4" /><span>Back to home</span></Link>
            <h1 className="lead-text text-7xl sm:text-8xl sm:leading-14">
              Contact <span className="font-bold">humanz</span>
            </h1>
            <p className="info-text md:text-2xl text-gray-400">wanna hangout?</p>
          </div>
        </div>
        <div className="normal-section mt-24">
          <div className="container mx-auto px-8 sm:px-48">
            <div className="content text-gray-500">
              <div className="flex flex-col sm:flex-row">
                <div className="flex-col mt-4 sm:pr-6">
                  <h5 className="md:text-xl font-bold uppercase mt-4">Email</h5>
                  <p className="para leading-7 mt-2">
                    <a href="mailto:support@humanzstudioz.com" className="link">support[at]humanzstudioz.com</a>
                  </p>
                </div>
                {/* <div className="flex-col mt-4 sm:pl-6">
                  <h5 className="md:text-xl font-bold uppercase mt-4">Address</h5>
                  <p className="para leading-7 mt-2">
                    ----
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
    )
  }